/* @import "~antd/dist/antd.css"; */
body {
  font-family: "Lato", sans-serif !important;
}
a {
  text-decoration: underline;
}

h1,
h2 {
  font-family: "Markazi Text", serif;
  margin: 0;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
  margin: 0;
  line-height: 28px;
}

h3,
h3.day-title,
h3.form-label,
.ant-form label {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 12px !important;
  font-weight: 700;
}

.ant-form-item-label {
  line-height: 20px !important;
  margin-top: 5px;
}

.ant-form-item-label label::after {
  content: "" !important;
}

.task-form-dates {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0px !important;
  margin: 0 -5px;
}

.task-form-dates > .ant-form-item-control {
  line-height: 30px;
}

.task-form-dates > div {
  margin-top: 5px;
  border-color: transparent;
  border-style: solid;
  border-width: 0 5px;
  flex-grow: 1;
  /* flex: 1 1 auto; */

  /* width: calc(50% - 5px); */
}

.task-form-name-date div {
  flex-grow: 1;
  margin: 2px;
}

.task-form-name-date .ant-calendar-picker {
  width: 100%;
}

.task-form-row + .task-form-row {
  margin-top: 10px;
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.93);
}

.ant-modal-content {
  background: none;
  box-shadow: none;
}

.ant-modal-header {
  background: none;
  padding: 16px 0;
}

.ant-modal-title {
  color: #fff;
  font-family: "Markazi Text", serif;
  font-size: 36px;
}

.ant-modal-body {
  background-color: #fff;
  padding: 10px !important;
  border-radius: 4px;
}

.ant-modal-close {
  /* color: #fff; */
}

.ant-dropdown {
  position: fixed;
}
